export default {
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  colors: {
    text: "#000",
    background: "#fff",
    primary: "#FFB976",
    secondary: "#8276FF",
    accent: "#fa5766",
    muted: "#EAEAEA",
  },
  shadows: [
    "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
    "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
    "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
    "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)",
  ],
  styles: {
    root: {
      lineHeight: "body",
      fontWeight: "body",
    },
    h1: {
      color: "text",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 6,
    },
    h2: {
      color: "text",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 5,
    },
    h3: {
      color: "text",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 4,
    },
    h4: {
      color: "text",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 3,
    },
    h5: {
      color: "text",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 2,
    },
    h6: {
      color: "text",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 1,
    },
    p: {
      color: "text",
      fontWeight: "body",
      lineHeight: "body",
    },
    a: {
      color: "secondary",
    },
    pre: {
      overflowX: "auto",
      code: {
        color: "inherit",
      },
    },
    code: {
      fontSize: "inherit",
    },
    table: {
      width: "100%",
      borderCollapse: "separate",
      borderSpacing: 0,
    },
    th: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
    td: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
    img: {
      maxWidth: "100%",
    },
  },
  text: {
    default: {
      color: "text",
      lineHeight: "body",
    },
    large: {
      variant: "text.default",
      fontSize: [3, 4],
    },
    bold: {
      variant: "text.default",
      fontWeight: 700,
    },
    important: {
      variant: "text.default",
      fontSize: 3,
    },
    // map: {
    //   variant: "text.default",
    //   fontSize: 2,
    //   bg: "white",
    // },
  },
  buttons: {
    primary: {
      bg: "primary",
      color: "text",
      boxShadow: 2,
      "&:hover": {
        boxShadow: 3,
      },
    },
    disabled: {
      bg: "muted",
      color: "text",
    },
    hidden: {
      bg: "background",
      color: "text",
      borderRadius: "0",
    },
    hiddenDisabled: {
      color: "red",
      variant: "buttons.hidden",
      textDecoration: "line-through",
    },
  },
  cards: {
    primary: {
      p: [3, 4],
    },
    dense: {
      p: [2, 3],
    },
    edgeToEdge: {
      p: 0,
    },
  },
  badges: {
    primary: {
      variant: "text.default",
      color: "white",
      fontSize: 1,
      p: 1,
      bg: "accent",
    },
    large: {
      variant: "badges.primary",
      p: 2,
      fontSize: 2,
      bg: "primary",
    },
  },
};
