// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-abgebrochen-js": () => import("./../../../src/pages/abgebrochen.js" /* webpackChunkName: "component---src-pages-abgebrochen-js" */),
  "component---src-pages-erfolg-js": () => import("./../../../src/pages/erfolg.js" /* webpackChunkName: "component---src-pages-erfolg-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-erfolg-js": () => import("./../../../src/pages/kontaktErfolg.js" /* webpackChunkName: "component---src-pages-kontakt-erfolg-js" */),
  "component---src-templates-wohnung-js": () => import("./../../../src/templates/Wohnung.js" /* webpackChunkName: "component---src-templates-wohnung-js" */)
}

